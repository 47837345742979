body {
  margin: 0;
  font-family: 'Roboto',  'Nunito Sans', Lato, 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
}

h1,h2,h3,h4 {
  font-family: "Outfit", "Poppins", "Nunito Sans", "Roboto", sans-serif; line-height: 1.7;
}